@import url('../../variables.css');

.navbar {
  position: fixed;
  width: 100%;
  background-color: var(--navbar-footer-color);;
  z-index: 1000;
  top: 0;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.logo a {
  color: var(--accent-color);
  font-size: 1.5rem;
  font-weight: 700;
}

.nav-links a {
  color: var(--light-color);
  margin-left: 30px;
  font-size: 1rem;
  position: relative;
  padding-bottom: 5px;
}

.nav-links a::after {
  content: '';
  display: block;
  width: 0%;
  height: 2px;
  background-color: var(--accent-color);
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav-links a:not(.active):hover::after {
  width: 100%;
}

.nav-links a.active::after {
  width: 100%;
}

.nav-links a:hover,
.nav-links a.active {
  color: var(--accent-color);
  cursor: pointer;
}

.nav-links a {
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: none;
}
