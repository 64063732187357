/* src/styles/Section.css */
@import url('../../variables.css');

/* Common Section Styles */
.section {
  padding: 100px 0 60px 0;         /* Top padding accounts for navbar height */
  min-height: calc(100vh - 80px); /* Subtract navbar height */
  display: flex;
  flex-direction: column;
}

.section h2 {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 2.5rem;
  text-align: center; /* Center the section title */
}

.section-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Introduction Section */
.intro-section {
  background-color: var(--primary-color);
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Optional: Limit the max width */
  margin: 0 auto;    /* Center the container */
}

.intro-text {
  flex: 1;
  padding: 20px;
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
  text-align: center; /* Center the text */
}

.intro-text h1 {
  font-size: 3rem;
  margin-bottom: 0px;
}

.intro-details {
  text-align: center;
}

.intro-details p {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-button,
.resume-button {
  background-color: transparent;
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-decoration: none;
  font-size: 1rem;
}

.contact-button:hover,
.resume-button:hover {
  background-color: var(--accent-color);
  color: var(--dark-color);
}

.scroll-down-text {
  font-size: 1rem;
  color: var(--light-color);
}

.scroll-down-text a {
  color: var(--accent-color);
  text-decoration: underline;
  cursor: pointer;
}

.scroll-down-text a:hover {
  color: var(--light-color);
}

.intro-image {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.intro-image img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s;
}

.intro-image img:hover {
  transform: scale(1.05);
}

/* Experience, Projects, and Achievements Grids */
.experience-grid,
.project-grid,
.achievement-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.experience-item,
.project-item,
.achievement-item {
  flex: 1 1 45%;
  background-color: var(--secondary-color);
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: transform 0.3s;
}

.experience-item:hover,
.project-item:hover,
.achievement-item:hover {
  transform: translateY(-5px);
}

/* Experience and Project Titles */
.experience-item h3,
.project-item h3 {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-bottom: 10px;
}

/* Achievement Titles */
.achievement-item h3 {
  
  font-size: 1.5rem;
  color: var(--light-color); /* Light blue color */
  margin: 0;
  text-align: center;
}

/* Adjusted Experience Item Styles */
.experience-item .role-duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.experience-item .role-duration h3 {
  margin: 0;
}

.experience-item .duration {
  font-size: 1.1rem;
  color: var(--light-color);
}

.experience-item .company {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--light-color);
  margin-bottom: 10px;
}

/* Description Lists */
.experience-item ul,
.project-item ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;
}

.experience-item li,
.project-item li {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
}

/* Project Links */
.project-item a {
  color: var(--accent-color);
  font-weight: 600;
}

.project-item a:hover {
  text-decoration: underline;
}

/* Achievements Styles */
.achievement-item {
  flex: 1 1 30%; /* Adjust this value to control the number of items per row */
}

.achievement-item h3 {
  font-size: 1.5rem;
  color: var(--light-color); /* Light blue color */
  margin: 0;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Adjust Introduction Section for Mobile */
  .intro-container {
    flex-direction: column;
    text-align: center;
  }

  .intro-text,
  .intro-image {
    padding: 10px;
  }

  .intro-text h1 {
    font-size: 2.5rem;
  }

  .intro-image img {
    width: 200px;
    height: 200px;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  /* Adjust Experience, Project, and Achievement Items for Mobile */
  .experience-item,
  .project-item,
  .achievement-item {
    flex: 1 1 100%;
  }

  .experience-item .role-duration {
    flex-direction: column;
    align-items: flex-start;
  }

  .experience-item .duration {
    margin-top: 5px;
  }

  /* Center section titles on mobile */
  .section h2 {
    text-align: center;
  }
}
