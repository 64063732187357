:root {
  --primary-color: #0a192f;
  --secondary-color: #2c3e50; 
  --accent-color: #4da8da; 
  --light-color: #f0f4f8; 
  --dark-color: #0a192f; 
  --navbar-footer-color: #1a2e45;   
  --font-primary: 'Roboto', sans-serif;
  --font-secondary: 'Merriweather', serif;
}
