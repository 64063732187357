@import url('./variables.css');

body {
  margin: 0;
  font-family: var(--font-primary);
  background-color: var(--dark-color);
  color: var(--light-color);
  scroll-behavior: smooth;
  line-height: 1.6;
}

h1, h2, h3 {
  font-family: var(--font-secondary);
  color: var(--accent-color);
  margin-top: 0;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

a {
  color: var(--accent-color);
  text-decoration: none;
}

a:hover {
  text-decoration: none; 
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}
