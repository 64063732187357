@import url('../../variables.css');

.footer-section {
  background-color: var(--navbar-footer-color);;
  color: var(--light-color);
  padding: 20px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links a {
  color: var(--light-color);
  margin-left: 15px;
  font-size: 1.5rem;
}

.social-links a:hover {
  color: var(--accent-color);
}
